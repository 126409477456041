import { ReactNode } from "react"
import LoadingSvg from "@/assets/loading.svg"

export const LoadingCover = ({ isLoading, children, message = 'Please wait'}: {
  isLoading: boolean
  message?: string
  children: ReactNode
}) => {
  return <>
    {isLoading && (
      <div className='z-50 animate-pulse opacity-75 relative'>
        <div className="absolute w-full">
          <div className="w-56 p-4 mx-auto bg-navy-600 rounded-lg text-center shadow">
            <LoadingSvg />
            <p className="text-white">{message}</p>
          </div>
        </div>
      </div>
    )}
    {children}
  </>
}