'use client'
import { searchParamsSearch } from '@/lib/searchParams';
import { sendGTMEvent } from '@next/third-parties/google';
import { usePathname } from 'next/navigation';
import { useQueryStates, Values } from 'nuqs';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type SearchUiContextType = {
  isVisible: boolean;
  runSearch: (query: string | null, products?: string[] | null, referer?: string) => void;
  hideSearchUi: () => void;
  showSearchUi: () => void;
  current?: Search
};

const SearchUiContext = createContext<SearchUiContextType>(null!);

export const useSearchUi = () => useContext(SearchUiContext);

type SearchUiProviderProps = {
  children: ReactNode;
};

export const SearchUiProvider: React.FC<SearchUiProviderProps> = ({ children }) => {
  const [params, setParams] = useQueryStates(searchParamsSearch, { clearOnDefault: true })
  const [isVisible, setIsVisible] = useState(!!params.searchQuery);
  const [currentSearch, setCurrentSearch] = useState<Search|undefined>(Search.fromQueryParams(params))
  const pathname = usePathname()

  useEffect(() => {
    if (isVisible && currentSearch?.searchPhrase && params.searchQuery != currentSearch.searchPhrase) {
      setParams(currentSearch.asParams())
    }
  }, [pathname, currentSearch, isVisible, setParams, params.searchQuery])

  const runSearch = (query: string | null = null, products: string[] | null = null, referer: string | null = null) => {

    const search = currentSearch?.augment(query, products, referer) ?? new Search(query, products, referer)

    setParams({
      ...params,
      searchQuery: search?.searchPhrase ?? null,
      searchFiltersProducts: search?.products ?? null
    })

    if (!isVisible) {
      sendGTMEvent({
        event: 'searchSidebar',
        value: 'show'
      })
      setIsVisible(true);
    }
    setCurrentSearch(search)
  }

  const showSearchUi = () => {
    if (!isVisible)  {
      sendGTMEvent({
        event: 'searchSidebar',
        value: 'show'
      })
      setIsVisible(true);
      if (currentSearch) {
        setParams(currentSearch.asParams())
      }
    }
  }

  const hideSearchUi = () => {
    if (isVisible)  {
      sendGTMEvent({
        event: 'searchSidebar',
        value: 'hide'
      })
      setIsVisible(false);
      setParams({
        searchQuery: null,
        searchFiltersProducts: null
      })
    }
  }

  return (
    <SearchUiContext.Provider value={{ isVisible, runSearch, hideSearchUi, showSearchUi, current: currentSearch }}>
      {children}
    </SearchUiContext.Provider>
  );
};

export interface SearchInterface {
  searchPhrase: string | null;
  products?: string[] | null;
  referer?: string | null;
  previous?: Search;
}

class Search implements SearchInterface {
  searchPhrase: string | null;
  products?: string[] | null;
  referer?: string | null;
  previous?: Search;

  /**
   * @param searchPhrase
   * @param products
   */
  constructor(searchPhrase: string | null, products?: string[] | null, referer?: string | null, previous?: Search) {
    this.searchPhrase = searchPhrase;
    this.products = products
    this.referer = referer
    this.previous = previous
  }

  augment(searchPhrase: string | null, products?: string[] | null, referer?: string | null): Search {
    return new Search(searchPhrase, products, referer, this)
  }

  asParams() {
    return {
      searchQuery: this.searchPhrase,
      searchFiltersProducts: this.products ?? []
    }
  }

  static fromQueryParams(params: Values<typeof searchParamsSearch>): Search {
    return new Search(params.searchQuery, params.searchFiltersProducts, 'url')
  }

  static getDefaultParams() {
    return {
      searchQuery: null,
      searchFiltersProducts: []
    }
  }
}