import {
  DOMNode,
  Element,
  Text,
  HTMLReactParserOptions,
  domToReact,
  attributesToProps,
} from "html-react-parser"; // highlight-line
import parse from "html-react-parser";
import Link from "next/link"; // highlight-line
import { CodeHighlight, highlightLanguages } from "./CodeHighlight.client";
import {Tabs, TabsProps, TabTagName} from "./Tabs.client";
import classNames from "classnames";
import { NodeWithChildren } from "domhandler";
import { StatusMessage, StatusMessageProps } from "./StatusMessage";
import { ModalImage } from "./ModalImage.client";
import { VersionedContent } from "./VersionedContent";
import '@/public/assets/wysiwyg.scss'
import { SmartLink } from "./SmartLink.client";
import { Faq } from "../widget/Faq.client";
import { ScrollToHash } from "../block/ScrollToHash.client";
import { DrupalNodeArticle, DrupalNodeBasicPage, DrupalNodeModeratedRevision } from "@/types";
import { ServerReleaseNotes } from "../block/ReleaseNotes";
import { Suspense } from "react";

// Mention class names used in CKeditor here so they are rendered into CSS.
("!text-white hover:bg-white hover:!text-pink-600 hover:!text-orange-600 hover:!text-teal-600 hover:!text-navy-600");
("shadow hover:shadow-pink-500/50  hover:shadow-orange-500/50  hover:shadow-teal-500/50  hover:shadow-navy-500/50");
("rounded-xl p-4 flex-grow shadow font-bold  text-lg text-navy-600 bg-white pl-20 hover:shadow-inner no-underline");
("inline-block");

function getOptions(context: BodyProps["context"]): HTMLReactParserOptions {
  return  {
    replace: (domNode: DOMNode) => {

      if (!(domNode instanceof Element)) {
        return;
      }

      switch (domNode.tagName) {
        case "body":
          // Don't allow body tags.
          return <>{domToReact(domNode.children, getOptions(context))}</>
        case "img":
          // Convert images to modal pop-ups so they can be enlarged.
          return <ModalImage {...attributesToProps(domNode.attribs)} />
        case "a":
          // Convert <a> tags into Next.js Link elements.
          return convertAnchor(domNode)
        case "nav":
          // Removing old table of content from migration.
          if (domNode.attribs.id === "contents") {
            return <></>
          }
          break;
        case "h2":
        case "h3":
        case "h4":
          // Set IDs and permalinks on headings.
          return convertHeading(domNode, getOptions(context))
        case "div":
          return convertDiv(domNode, context, getOptions(context))
        case "table":
          return (  
            <div className="table-overflow-auto">
              <domNode.tagName {...attributesToProps(domNode.attribs)}>
                    {domToReact(domNode.children, getOptions(context))}
              </domNode.tagName>
            </div>
          )
        case "code":
          if (domNode.parentNode instanceof Element && domNode.parentNode.name === "pre") {
            return convertCode(domNode)
          }
          break;
        case "canvas":
          return convertCanvas(domNode, context, getOptions(context))
      }
    },
  };
}

interface BodyProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string;
  sectionScroll?: boolean;
  context?: {
    resource?: DrupalNodeModeratedRevision,
    version?: string
  }
}

export function Body({ value, ...props }: BodyProps) {
  if (!value) {
    return <></>
  }
  
  return (
    <div className={classNames("ck", props.className ?? "")}>
      { props.sectionScroll ? 
        <ScrollToHash>
          {parse(value, getOptions(props.context))}
        </ScrollToHash> 
        : parse(value, getOptions(props.context))
      }
    </div>
  );
}

export function LoadingBody() {
  return <div className="animate-pulse">
    <p className="p-1 rounded bg-gray-600">&nbsp;</p>
    <p className="p-1 rounded bg-gray-600">&nbsp;</p>
    <p className="p-1 rounded bg-gray-600">&nbsp;</p>
    <p className="p-1 rounded bg-gray-600">&nbsp;</p>
  </div>
}

/**
 * Convert headings into anchored tags.
 *
 * @param domNode 
 * @param options 
 * @returns 
 */
function convertHeading(domNode: Element, options: HTMLReactParserOptions) {
  if (!domNode.attribs.id?.length) {
    domNode.attribs.id = asId(extractText(domNode.children));
  }

  if (domNode.attribs.class) {
    domNode.attribs.className = domNode.attribs.class
    delete domNode.attribs.class
  }
  const Tag = domNode.tagName as 'h1' | 'h2' | 'h3' | 'h4'
  return (
    <Tag
      {...domNode.attribs}
      className={classNames(domNode.attribs.className, "group")}
    >
      {domToReact(domNode.children, options)}
      <a
        href={`#${domNode.attribs.id}`}
        className="ref hidden group-hover:inline pl-1"
        title="Permalink to this heading"
      >
        ¶
      </a>
    </Tag>
  );
}

/**
 * Convert anchors into Link components.
 *
 * @param domNode 
 * @returns 
 */
function convertAnchor(domNode: Element) {
  const { href, class: className, target, id, title } = domNode.attribs;

  // Remove headerlinks from migration.
  if (className == "headerlink") {
    return <></>;
  }

  // TODO: Find better ways to render CKEditor mentions (DRCD-435).
  if (className == "mention" && title != undefined) {
    return <>{title}</>
  }
  
  let newHref = href

  if(!href && id) {
    newHref = '#' + id;
  }
  if (!newHref) {
    return (
      <>
        {domToReact(domNode.children)}
      </>
    )
  }

  if (className?.includes('smartlink')) {
    return (
      <SmartLink {...attributesToProps(domNode.attribs)} href={newHref} className={className} target={target}>
        {domToReact(domNode.children)}
      </SmartLink>
    );
  }

  return (
    <Link {...attributesToProps(domNode.attribs)} href={newHref} className={className} target={target}>
      {domToReact(domNode.children)}
    </Link>
  );
}

function convertCanvas(domNode: Element, context: BodyProps["context"], options: HTMLReactParserOptions) {
  if (domNode.attribs["data-component"] == "rn-traditional") {
    if (!context?.resource) {
      console.warn("Cannot display release notes without a resource context.")
      return <></>
    }

    const product = context.resource.type == 'node--article' 
      ? 
      (context.resource as DrupalNodeArticle).field_products[0]
      :
      (context.resource as DrupalNodeBasicPage).field_product

    return <Suspense fallback={<LoadingBody />}><ServerReleaseNotes style="traditional" product={product}  /></Suspense>
  }

  if (domNode.attribs["data-component"] == "rn-modern") {
    if (!context?.resource) {
      console.warn("Cannot display release notes without a resource context.")
      return <></>
    }

    const product = context.resource.type == 'node--article' 
      ? 
      (context.resource as DrupalNodeArticle).field_products[0]
      :
      (context.resource as DrupalNodeBasicPage).field_product

    return <Suspense fallback={<LoadingBody />}><ServerReleaseNotes style="modern" product={product}  /></Suspense>
  }
}

function convertDiv(domNode: Element, context: BodyProps["context"], options: HTMLReactParserOptions) {
  if (domNode.attribs.class?.includes("widget-faq") && !domNode.attribs.class?.includes("widget-faq-")) {
    return convertFaq(domNode, getOptions(context))
  }

  if (domNode.attribs.class?.includes("widget-tabs") && !domNode.attribs.class?.includes("widget-tabs-")) {
    return convertTabs(domNode, getOptions(context))
  }

  if (domNode.attribs.class?.includes("widget-status-message") && !domNode.attribs.class?.includes("widget-status-message-")) {
    return convertStatusMessage(domNode, getOptions(context))
  }

  // Legacy style: CKEditor Embedded Content widget.
  if (domNode.attribs["data-component-type"] !== undefined) {
    const props =
      domNode.attribs["data-component"] !== undefined
        ? JSON.parse(domNode.attribs["data-component"])
        : {};
    switch (domNode.attribs["data-component-type"]) {
      case "VersionedContent": 
        return <VersionedContent {...props} version={context?.version}>{domToReact(domNode.children, options)}</VersionedContent>
      case "Tabs":
        const tabs = (props as TabsProps).tabs.map(t => {
          return {
            ...t,
            labelTagName: 'div' as TabTagName
          }
        })
        return <Tabs tabs={tabs} />;
      case "StatusMessage":
        return (
          <StatusMessage {...props}>
            {domToReact(domNode.children, options)}
          </StatusMessage>
        );
    }
  }
  // Migrated tabs
  else if (
    domNode.attribs.class != undefined &&
    domNode.attribs.class.indexOf("sphinx-tabs ") !== -1
  ) {
    const tabList = domNode.children.filter(
      (e) => e instanceof Element && e.attribs.role == "tablist"
    );
    if (!tabList.length) {
      return;
    }
    const tabListEl = tabList[0];
    if (!(tabListEl instanceof Element)) {
      return;
    }
    const tabLabels = tabListEl.children
      .map((c) => (c instanceof Element ? c.children[0] : []))
      .map((d) => (d instanceof Text ? d.data : ""));

    const tabContentList = domNode.children.filter(
      (e) => e instanceof Element && e.attribs.role == "tabpanel"
    );
    if (!tabContentList.length) {
      return;
    }

    const tabs = tabLabels.map((label, i) => {
      const node =
        tabContentList[i] instanceof Element ? tabContentList[i] : null;
      if (node instanceof Element) {
        return {
          label,
          node: domToReact(node.children, options),
        };
      }
      return {
        label,
        content: { processed: "" },
      };
    });
    return <Tabs tabs={tabs} />;
  }
}

/**
 * Convert code blocks into syntax highlighted blocks.
 *
 * @param domNode
 * @returns 
 */
function convertCode(domNode: Element) {
  const languages = (domNode.attribs.class ?? domNode.attribs.className ?? 'language-bash')
    .split(" ")
    .filter((className) => className.indexOf("language-") === 0);
  if (!languages.length) {
    return;
  }

  // We can only syntax highlight text nodes. If there are Elements in the children set. We can't
  // render it.
  const textNodes: Text[] = domNode.children.filter(
    (node): node is Text => node instanceof Text
  );
  if (textNodes.length < domNode.children.length) {
    return;
  }

  const lang = languages[0].replace(
    "language-",
    ""
  ) as keyof typeof highlightLanguages;
  const code = textNodes.map((n) => n.data).join("\n");

  return <CodeHighlight language={lang} code={code} />;
}

/**
 * Helper function to extract text from children.
 * 
 * @param children 
 * @returns 
 */
function extractText(children: NodeWithChildren["children"]): string {
  const text = children.map((el) => {
    if (el instanceof Element) {
      return extractText(el.children);
    }
    if (el instanceof Text) {
      return el.data;
    }
    return "";
  });

  return text.join("");
}

/**
 * Helper function to create an ID from text.
 *
 * @param text 
 * @returns string
 */
function asId(text: string):string {
  return (
    "section-" +
    text
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll(/[^a-z0-9\-]/g, "")
  );
}

function convertFaq(domNode: Element, options: HTMLReactParserOptions) {
  const children = domNode.children.filter(
    (node): node is Element => node instanceof Element
  );

  const questions = children.filter((c) =>
    c.attribs.class?.includes("widget-faq-q")
  );
  const answers = children.filter((c) =>
    c.attribs.class?.includes("widget-faq-a")
    && !c.attribs.class?.includes("widget-faq-add")
  );

  if (questions.length !== answers.length) {
    return;
  }

  const faqs = questions.map((question, i) => {
    return {
      question: domToReact(question.children, options),
      answer: domToReact(answers[i].children, options),
    };
  });

  return <Faq faqs={faqs} />;
}

function convertTabs(domNode: Element, options: HTMLReactParserOptions) {
  const children = domNode.children.filter(
    (node): node is Element => node instanceof Element
  );

  const labels = children.filter((c) =>
    c.attribs.class?.includes("widget-tabs-title")
  );
  const containers = children.filter((c) =>
    c.attribs.class?.includes("widget-tabs-container")
  );

  if (labels.length !== containers.length) {
    return;
  }

  const tabs = labels.map((label, i) => {
    return {
      label: domToReact(label.children, options),
      node: domToReact(containers[i].children, options),
    };
  });

  return <Tabs tabs={tabs} />;
}

function convertStatusMessage(domNode: Element, options: HTMLReactParserOptions) {
  const children = domNode.children.filter(
    (node): node is Element => node instanceof Element
  );

  // Use the first valid status found searched for in order of severity.
  const statuses:StatusMessageProps["type"][] = ['alert', 'advisory', 'warning', 'caution', 'important', 'note'];
  const classes = domNode.attribs.class.split(' ')
  const availableStatuses = statuses.filter(s => classes.includes(s))
  // Default to "note" if no other statuses are found.
  const type = availableStatuses[0] ?? statuses.pop()
  
  const title = children.filter((c) =>
    c.attribs.class?.includes("widget-status-message-title")
  );

  const description = children.filter((c) =>
    c.attribs.class?.includes("widget-status-message-description")
  );

  const headingText = extractText(title);

  return (
    <StatusMessage type={type} heading={headingText}>
      {domToReact(description, options)}
    </StatusMessage>
  );
}
