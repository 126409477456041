import { DrupalClient, DrupalClientAuthClientIdSecret, DrupalClientOptions } from 'next-drupal';

// clientId and clientSecret are not needed and not accessible at buildtime.
export const drupalAuth:DrupalClientAuthClientIdSecret = {
  clientId: process.env.DRUPAL_CLIENT_ID as string,
  clientSecret: process.env.DRUPAL_CLIENT_SECRET as string,
  url: '/oauth/token'
}

const opts:DrupalClientOptions = {
  previewSecret: process.env.DRUPAL_PREVIEW_SECRET, // Config.DRUPAL_PREVIEW_SECRET,
  // @see https://github.com/vercel/next.js/discussions/32238
  // @see https://github.com/vercel/next.js/blob/d895a50abbc8f91726daa2d7ebc22c58f58aabbb/packages/next/server/api-utils/node.ts#L504
  forceIframeSameSiteCookie: process.env.NODE_ENV === 'development',

  fetcher: async (input, init) => {
    const url = new URL(input as string)
    let next:NextFetchRequestConfig = {
      tags: ['drupal'],
      revalidate: 3600
    }
    const defaults:DrupalClientOptions = {}

    if (init == undefined) {
      init = {}
    }

    // init is of type RequestInit which doesn't contain withAuth
    // yet that value is passed into this function. So we fool
    // typescript here by merging init with defaults which is
    // of type DrupalClientOptions which includes withAuth
    const opts = {...init, ...defaults}

    // Non-cacheable scenarios.
    // Request needs to be a GET or HEAD to be cacheable.
    if (['GET', 'HEAD'].indexOf(opts.method ?? 'GET') === -1) {
      init.cache = 'no-store';
      return await fetch(input, init);
    }

    // Use of credentials means no caching.
    if (opts.withAuth) {
      init.cache = 'no-store';
      return await fetch(input, init);
    }

    // From here, we can cache a response.

    // Insert the default next caching if its not already set.
    if (init.next == undefined) {
      init.next = next
    }

    // Tag as decoupled router.
    if (url.pathname == '/router/translate-path') {
      init.next.tags?.push(url.searchParams.get('path') ?? 'no-path')
    }

    // /jsonapi/taxonomy_term/<tag>
    const taxonomy_tags = url.pathname.match(/\/jsonapi\/taxonomy_term\/([a-z0-9\-_]+)/)
    if (taxonomy_tags !== null) {
      init.next.tags?.push('taxonomy_term:' + taxonomy_tags[1])
    }
    
    // /jsonapi/node/page/<tag>
    const node_tags = url.pathname.match(/^\/jsonapi\/node\/([a-z]+)\/([a-z0-9\-]+)/)
    if (node_tags !== null) {
      init.next.tags?.push(node_tags[2])
    }

    // /jsonapi/menu_items/<tag>
    const menu_tags = url.pathname.match(/^\/jsonapi\/menu_items\/([a-z0-9\-\_]+)/)
    if (menu_tags !== null) {
      let menu_tag = menu_tags[1]
      if (url.searchParams.get('filter[version]')) {
        menu_tag += ':' + url.searchParams.get('filter[version]')
      }
      init.next.tags?.push('menu:' + menu_tag)
    }

    return await fetch(input, init)
  }
};

export const drupal = new DrupalClient(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL as string, opts);
