'use client'
import { DrupalTaxonomyTermProduct } from "@/types";
import { createContext, ReactNode, useContext } from "react";

export type ProductsContext = {
  products: DrupalTaxonomyTermProduct[]
}

const ProductsContext = createContext<ProductsContext>({
  products: []
})

export const useProducts = () => useContext(ProductsContext);

export const ProductsProvider = (props: ProductsContext & {
  children: ReactNode
}) => {
  return <ProductsContext.Provider value={props}>
    {props.children}
  </ProductsContext.Provider>
}